@import 'styles/includes.folkhem.scss';

.AnnualRing {
    display: none;

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @include media(lg) {
        animation: fadeIn 1s 0.5s ease forwards;
        opacity: 0;
        display: block;
        position: absolute;
        z-index: -1;
        width: calc(100% - #{$gutter});
        height: calc(100% - #{$gutter});
    }

    &--1 {
        @include media(lg) {
            top: 50px;
            left: calc(#{$gutter} / 2);
        }
    }

    &--2 {
        @include media(lg) {
            top: 150px;
            left: -50%;
        }
    }

    &--3 {
        @include media(lg) {
            top: 50px;
            right: -25%;
        }
    }

    &--4 {
        @include media(lg) {
            bottom: 0px;
            right: 33%;
        }
    }

    &__Svg {
    }
}
